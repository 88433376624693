export const categories = [{
  title: "All Categories",
  value: "*"
}, {
  title: "Car",
  value: "car"
}, {
  title: "Clothes",
  value: "clothes"
}, {
  title: "Electronics",
  value: "electronics"
}, {
  title: "Laptop",
  value: "laptop"
}, {
  title: "Desktop",
  value: "desktop"
}, {
  title: "Camera",
  value: "camera"
}, {
  title: "Toys",
  value: "toys"
}];